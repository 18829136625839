<template>
	<div>
		<div class="editor-ot-users">
			<div class="editor-ot-users-content" size="small">
				<template v-if="members.length">
					<div class='tooltip' key={member.uuid} title={member.name} v-for="({ uuid, name, color,workcode }, index) in members" :key="index">
						<template v-if="index <= maxCount-1">
							<el-tooltip :content="workcode" placement="top" effect="dark">
									<el-avatar size="small" :style="{backgroundColor:color}" shape="circle" fit="fill">{{ name }}</el-avatar>
								</el-tooltip>
						</template>
					</div>
				</template>
				<div v-if="moreMembers.length">
					<div class="editor-ot-more-button">
						<el-avatar size="small" :style="{backgroundColor:colorFunc()}" shape="circle" fit="fill">+{{ moreMembers.length }}</el-avatar>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import OTClient, { STATUS, EVENT } from './client.ts';
export default {
	props: {
		ot: {
			type: Object,
			default: () => { },
		},
		// members: {
		// 	type: Array,
		// 	default: () => [],
		// },
		editor: {
			type: Object,
			default: () => { },
		},

	},
	watch:{
		members(newval){
			const len = newval.length;
			for (let i = this.maxCount; i < len; i++) {
				this.moreMembers.push(newval[i]);
			}
		},
		'ot.members':{
			handler(newval){
				this.updateCurrentUser(newval)
			},
			immediate: true
		}
	},
	mounted() {
		this.updateCurrentUser()
		// const localMember =
		// typeof localStorage === 'undefined' ? null : localStorage.getItem('member');

		// const getMember = () => {
		// 	return !!localMember ? JSON.parse(localMember) : null;
		// };

		// const IS_DEV = true
		// const wsUrl =
		// 	IS_DEV 
		// 		// ? `ws://${window.location.hostname}:8080`
		// 		? `ws://localhost:8080`
		// 		: 'wss://collab.aomao.com';
		// const member = getMember();
		// //实例化协作编辑客户端
		// const ot = new OTClient(this.editor);
		// const url =  `${wsUrl}${member?.id ? '?uid=' + member.id : ''}`
		// // const { url, docId, onReady } = props.ot;

		// const value =
		// 		this.defaultValue.paths.length > 0
		// 			? this.editor.command.executeMethod(
		// 					'mark-range',
		// 					'action',
		// 					'comment',
		// 					'wrap',
		// 					this.defaultValue.paths,
		// 					this.defaultValue.value,
		// 			  )
		// 			: this.defaultValue.value;
		// // 连接协同服务端，如果服务端没有对应docId的文档，将使用 defaultValue 初始化
		// ot.connect(url, this.docId, this.editor.getValue());
		// ot.on('ready', (member) => {
		// 	// if (onLoad) onLoad(editor);
		// 	this.onReadyFunc(member)
		// 	// setMember(member);
		// 	// setLoading(false);
		// 	// if (errorModal.current) errorModal.current.destroy();
		// 	// errorModal.current = null;
		// });
		// // ot.on('error', ({ code, message }) => {
		// // 	const errorMessage = (
		// // 		<p>
		// // 			{message}
		// // 			<LoadingOutlined />
		// // 		</p>
		// // 	);
		// // 	if (errorModal.current) {
		// // 		errorModal.current.update({
		// // 			title: code,
		// // 			content: errorMessage,
		// // 		});
		// // 	} else {
		// // 		errorModal.current = Modal.error({
		// // 			title: code,
		// // 			keyboard: false,
		// // 			mask: false,
		// // 			centered: true,
		// // 			content: errorMessage,
		// // 			okButtonProps: {
		// // 				style: { display: 'none' },
		// // 			},
		// // 		});
		// // 	}
		// // });
		// // otClient.current = ot;
		// // 用户加入或退出改变
		// const membersChange = (members) => {
		// 	// if (onMembersChange) onMembersChange(this.members);
		// 	this.$emit('membersChange', members)
		// };
		// ot.on('membersChange', this.membersChange);
		// // 状态改变，退出时，强制保存
		// const statusChange = (from,to) => {
		// 	// if (onStatusChange) onStatusChange(from, to);
		// 	// if (to === STATUS.exit) {
		// 	// 	userSave();
		// 	// }
		// };
		// ot.on('statusChange', statusChange);
		// // 错误监听
		// const error = (error) => {
		// 	// if (onError) onError(error);
		// };
		// ot.on('error', error);
		// // 消息监听
		// const message = (message) => {
		// 	// if (onMessage) onMessage(message);
		// };
		// ot.on('message', message);
	},
	methods: {
		onMembersChange(members){this.$emit('membersChange', members)},
		onStatusChange(from, to){},
		onError(error){},
		onMessage(message){},
		onReadyFunc(member) {
			if (member) localStorage.setItem('member',JSON.stringify(member))
		},
		colorFunc(){
			return `rgba(${parseInt(Math.random()*255)}, ${parseInt(Math.random()*255)}, ${parseInt(Math.random()*255)}, 0.45)`
		},
		updateCurrentUser(val){
			console.log('-------member----------member',val);
			this.members = val || this.ot.members || [];
			// 只显示不是当前登录人的
			this.members = this.members.filter(item => item.workcode !== this.$store.state.user.workcode)
		    // 去重
			// this.members = this.members.reduce((prev, cur) => {
			// 	const hasCur = prev.find(item => item.workcode === cur.workcode)
			// 	if (!hasCur) {
			// 		prev.push(cur)
			// 	}
			// 	return prev
			// }, [])
		}
	},
	data() {
		return {
			moreMembers: [],
			maxCount: 5,
			docId: this.$route.params.pathMatch,
			// docId: 'demo',
			defaultValue: {
				paths: [],
				value: '',
			},
			members: [],
	}
},
}
</script>
<style lang="scss" scoped>
.aClass {
	display: 'flex';
	flex-direction: flex-end;
	align-items: 'center';
	margin-bottom: 4;
}

.editor-ot-users {
	font-size: 12px;
	background: #ffffff;
	z-index: 999;
	margin: 0 24px;

}

.editor-ot-users-content {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	.tooltip{
		margin-left: 10px;
	}
}

.editor-ot-users .ant-avatar {
	margin: 0 2px;
}

.editor-toolbar {
	position: fixed;
	width: 100%;
	background: #ffffff;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02);
	z-index: 1000;
	top: 64px;
}

.__dumi-default-previewer-demo .editor-toolbar {
	top: 0;
}

.editor-wrapper {
	position: relative;
	width: 100%;
	min-width: 1440px;

}

.editor-container {
	padding: 24px 0 64px;
	height: calc(100vh - 106px);
	width: 100%;
	margin: 0 auto;
	overflow: auto;
	position: relative;

}

.editor-content {
	position: relative;
	width: 812px;
	margin: 0 auto;
	background: #fff;
	min-height: 800px;
}

.editor-content .am-engine {
	padding: 40px 0 60px;
}

</style>

