var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-tooltip',{attrs:{"placement":_vm.placement || 'bottom',"visible":(!!_vm.title || !!_vm.hotkeyText) && !_vm.isMobile ? _vm.visible : false},scopedSlots:_vm._u([{key:"title",fn:function(){return [(!!_vm.title)?_c('div',{staticClass:"toolbar-tooltip-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!!_vm.hotkeyText)?_c('div',{staticClass:"toolbar-tooltip-hotkey",domProps:{"innerHTML":_vm._s(_vm.hotkeyText)}}):_vm._e()]},proxy:true}])},[_c('button',{ref:"element",class:[
      'toolbar-button',
      _vm.className,
      {
        'toolbar-button-active': _vm.active,
        'toolbar-button-disabled': _vm.disabled,
      },
    ],on:{"click":_vm.triggerClick,"mousedown":_vm.triggerMouseDown,"mouseenter":_vm.triggerMouseEnter,"mouseleave":_vm.triggerMouseLeave}},[_vm._t("icon",function(){return [(_vm.iconIsHtml)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.icon)}}):_vm._e(),(!_vm.iconIsHtml && _vm.icon)?_c('span',{class:`data-icon data-icon-${_vm.icon}`}):_vm._e()]}),_vm._t("default",function(){return [_vm._v(_vm._s(typeof _vm.content === "function" ? _vm.content() : _vm.content))]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }