const autoAdjustOverflow = {
	adjustX: true,
	adjustY: true,
};

const targetOffset = [0, 0];

export default {
	left: {
		points: ['cr', 'cl'],
		overflow: autoAdjustOverflow,
		offset: [-4, 0],
		targetOffset: targetOffset,
	},
	right: {
		points: ['cl', 'cr'],
		overflow: autoAdjustOverflow,
		offset: [4, 0],
		targetOffset: targetOffset,
	},
	top: {
		points: ['bc', 'tc'],
		overflow: autoAdjustOverflow,
		offset: [0, -4],
		targetOffset: targetOffset,
	},
	bottom: {
		points: ['tc', 'bc'],
		overflow: autoAdjustOverflow,
		offset: [0, 4],
		targetOffset: targetOffset,
	},
	topLeft: {
		points: ['bl', 'tl'],
		overflow: autoAdjustOverflow,
		offset: [0, -4],
		targetOffset: targetOffset,
	},
	leftTop: {
		points: ['tr', 'tl'],
		overflow: autoAdjustOverflow,
		offset: [-4, 0],
		targetOffset: targetOffset,
	},
	topRight: {
		points: ['br', 'tr'],
		overflow: autoAdjustOverflow,
		offset: [0, -4],
		targetOffset: targetOffset,
	},
	rightTop: {
		points: ['tl', 'tr'],
		overflow: autoAdjustOverflow,
		offset: [4, 0],
		targetOffset: targetOffset,
	},
	bottomRight: {
		points: ['tr', 'br'],
		overflow: autoAdjustOverflow,
		offset: [0, 4],
		targetOffset: targetOffset,
	},
	rightBottom: {
		points: ['bl', 'br'],
		overflow: autoAdjustOverflow,
		offset: [4, 0],
		targetOffset: targetOffset,
	},
	bottomLeft: {
		points: ['tl', 'bl'],
		overflow: autoAdjustOverflow,
		offset: [0, 4],
		targetOffset: targetOffset,
	},
	leftBottom: {
		points: ['br', 'bl'],
		overflow: autoAdjustOverflow,
		offset: [-4, 0],
		targetOffset: targetOffset,
	},
};
