var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"toolbarRef",class:[
    'editor-toolbar',
    _vm.className,
    {
      'editor-toolbar-mobile': _vm.isMobile && !_vm.popup,
      'editor-toolbar-popup': _vm.popup,
    },
  ],style:(_vm.isMobile ? { top: `${_vm.mobileView.top}px` } : {}),attrs:{"data-element":"ui"},on:{"mouseover":_vm.triggerMouseOver,"mousemove":_vm.triggerMouseMove,"contextmenu":_vm.triggerContextMenu}},[_c('div',{staticClass:"editor-toolbar-content"},_vm._l((_vm.groupValue),function(group,index){return _c('am-group',_vm._b({key:index,attrs:{"engine":_vm.engine,"popup":_vm.popup}},'am-group',group,false))}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }