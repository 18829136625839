<template>
	<div class="data-toc-wrapper" v-if="datas.length">
		<div class="data-toc-title">
			大纲
		</div>
		<div class="data-toc" ref="rootRef">
			<a v-for="(data,i) in datas" :key="i" @click.prevent="scrollTo(data)" :href="'#' + data.id" :class="[`data-toc-item data-toc-item-${data.depth}`,{'data-toc-item-active': i === readingSection}]">
				{{ data.text }}
			</a>
		</div>
	</div>
</template>
<script>
import { Outline, OutlineData } from '@aomao/plugin-heading';
import {$, isEngine } from '@aomao/engine';
import { findReadingSection  } from './utils'
export default {
	props: {
		editor: Object
	},
	data() {
		return {
			datas: [],
			readingSection: -1,
			activeId: '', // 点击的锚点ID，滚动时取消选中时的focus效果
		}
	},
	mounted() {
		if (!this.editor) return;
		this.editor.on('change', this.onChange);
		this.editor.on('afterSetValue', this.onChange);
		if (isEngine(this.editor)) {
			this.editor.scrollNode?.on('scroll', this.listenerViewChange);
			this.editor.scrollNode?.on('resize', this.listenerViewChange);
		} else {
			window.addEventListener('scroll', this.listenerViewChange);
			window.addEventListener('resize', this.listenerViewChange);
		}
		this.listenerViewChange()
	},
	beforeDestroy() {
		if (!this.editor) return;
		this.editor.off('change', this.onChange);
		this.editor.off('afterSetValue', this.onChange);
		if (isEngine(this.editor)) {
			this.editor.scrollNode?.off('scroll', this.listenerViewChange);
			this.editor.scrollNode?.off('resize', this.listenerViewChange);
		} else {
			window.removeEventListener('scroll', this.listenerViewChange);
			window.removeEventListener('resize', this.listenerViewChange);
		}
	},
	methods: {
		onChange() {
			const data = this.getTocData();
			console.log('--------------', data);
			this.datas = data;
		},
		getTocData() {
			const outline = new Outline();
			// 从编辑区域提取符合结构要求的标题 Dom 节点
			const nodes = [];
			const { card } = this.editor;
			this.editor.container.find('h1,h2,h3,h4,h5,h6').each((child) => {
				const node = $(child);
				// Card 里的标题，不纳入大纲
				if (card.closest(node)) {
					return;
				}
				// 非一级深度标题，不纳入大纲
				if (!node.parent()?.isRoot()) {
					return;
				}
				nodes.push(node.get());
			});
			return outline.normalize(nodes);
		},
		listenerViewChange() {
			const data = this.datas
				.map(({ id }) => document.getElementById(id))
				.filter((element) => element !== null)
				// data-toc 下的a标签，取消focus
			const focusEle = document.querySelectorAll(`.data-toc a[href="#${this.activeId}"]`);
			if (focusEle.length > 0) {
				focusEle[0].blur()
			}
			const index = findReadingSection(data, 220);
			this.readingSection = index;
		},
		scrollTo(data) {
			console.log(data)
			const element = document.getElementById(data.id);
			const dom = document.getElementById('editor-container')
			if (element && dom) {
				console.log(12, element.offsetTop)
				dom.scrollTo({
					top: element.offsetTop,
					behavior: 'smooth',
				});
				this.activeId = data.id;
			}
		},
	}
}
</script>
<style scoped lang="less">
.data-toc-wrapper {
	position: absolute;
	top: 60px;
	max-width: 210px;
	padding: 0 16px;
}

.data-toc-title {
	position: relative;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 700;
	padding: 0 2px 8px;
	border-bottom: 1px solid #e8e8e8;
}

.data-toc {
	overflow: auto;
	height: calc(100vh - 209px);
}

.data-toc .data-toc-item {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	font-size: 14px;
	line-height: 24px;
	color: inherit;
}

.data-toc .data-toc-item-active,
.data-toc .data-toc-item:hover,
.data-toc .data-toc-item:focus {
	color: #1890FF;
	text-decoration: none;
}

.data-toc .data-toc-item-2 {
	padding-left: 16px;
}

.data-toc .data-toc-item-3 {
	padding-left: 32px;
}

.data-toc .data-toc-item-4 {
	padding-left: 48px;
}

.data-toc .data-toc-item-5 {
	padding-left: 64px;
}

.data-toc .data-toc-item-6 {
    padding-left: 80px;
}</style>