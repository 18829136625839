<template>
  <div class="amEditorVue2">
    <am-ot v-if="engine" :editor="engine" ref="amot" :ot="otClient.current" :members="members" @membersChange="membersChange" />
    <am-loading :loading="loading">
      <am-toolbar v-if="engine && !isReadonly" :engine="engine"  :items="items" />
      <div :class="['editor-wrapper', { 'editor-mobile': mobile }]">
        <div id="editor-container" class="editor-container">
          <div class="editor-content">
            <div ref="container"></div>
            <div @click.prevent.stop="addComment($event)"  class="data-comment-button-container" data-element="ui" :style="{'top': top+'px'}">
              <span class="data-icon data-icon-comment"></span>
            </div>
          </div>
          <am-component @broadcast="broadcast" v-if="engine" :isNotice="isNotice" :title="title" :chapterObj="chapterObj" :editor="engine" :chapterId="chapterId" ref="comment" @rangeSelect="changeTop" @updateNotice="updateNotice"></am-component>
        </div>
        <am-toc v-if="engine" :editor="engine" />
        <!-- <am-ot v-if="engine" :editor="engine" :members="[]"  /> -->
      </div>
      <!-- <div ref="view"></div> -->
    </am-loading>
    <map-modal
      v-if="engine"
      :engine="engine"
      :visible="mapModalVisible"
      :onVisibleChange="handleMapModalVisibleChange"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AmToc from "./Toc.vue";
import AmOt from "./ot/index.vue";
import AmComponent from "./Comment.vue";
import OT from './ot/packages/ot/index.ts';
import Engine from './ot/packages/engine/index.ts';
import OTSelection from "./ot/packages/ot/selection";
import  {
  $,
  EngineInterface,
  isMobile,
  View,
  ViewInterface,
} from "@aomao/engine";
import { message, Modal } from "ant-design-vue";
import AmLoading from "./Loading.vue";
import AmToolbar from "../../../packages/toolbar/src";
import { plugins, cards, pluginConfig } from "./config";
import MapModal from "./Map/Modal.vue";
// import MarkdownIt from 'markdown-it'
import { sendDDingMsg } from '@/api/space'

import OTClient, { STATUS, EVENT } from './ot/client.ts';
@Component({
  components: {
    AmLoading,
    AmToolbar,
    MapModal,
    AmToc,
    AmOt,
    AmComponent
  },
})

export default class Editor extends Vue {
  @Prop({ default: '<strong>Hello</strong>,<span style="color:red">am-editor-me</span>' }) mdStr
  @Prop({ default: false }) isReadonly
  @Prop({ default: null }) chapterId
  @Prop({ default: '未命名文档' }) title
  @Prop({ default: {} }) chapterObj

  loading = true;
  firstLoadData = false
  engine = null;
  view = null;
  mobile = isMobile;
  items = [];
  mapModalVisible = false;
  value = "";
  top = 0
  userList = []
  isNotice = false
  changeTimeout =null
  members = new Array(1).fill({name: 'cyy',
    uuid: 'adwlmldaadwwadw',
    color: '#f50',
    avatar:'',
    index:0,})
  otClient = {
    current: null,
  }
  selection = null
  checkedData = null
  sendUser = []
  handleMapModalVisibleChange(visible) {
    this.mapModalVisible = visible;
  }
  mounted() {
    if (this.$store.state.user.workcodeList.length === 0) {
      this.$store.dispatch('GetWorkcode')
    }
    this.items = isMobile
      ? [
          ["undo", "redo"],
          {
            icon: "text",
            items: ["bold", "italic", "strikethrough", "underline", "moremark"],
          },
          [
            {
              type: "button",
              name: "image-uploader",
              icon: "image",
            },
            "link",
            "tasklist",
            "heading",
          ],
          {
            icon: "more",
            items: [
              {
                type: "button",
                name: "video-uploader",
                icon: "video",
              },
              {
                type: "button",
                name: "file-uploader",
                icon: "attachment",
              },
              {
                type: "button",
                name: "table",
                icon: "table",
              },
              {
                type: "button",
                name: "math",
                icon: "math",
              },
              {
                type: "button",
                name: "codeblock",
                icon: "codeblock",
              },
              {
                type: "button",
                name: "orderedlist",
                icon: "orderedlist",
              },
              {
                type: "button",
                name: "unorderedlist",
                icon: "unorderedlist",
              },
              {
                type: "button",
                name: "hr",
                icon: "hr",
              },
            ],
          },
        ]
      : [
          [
            {
              type: "collapse",
              groups: [
                {
                  items: [
                    "image-uploader",
                    "codeblock",
                    "table",
                    "file-uploader",
                    "video-uploader",
                    "math",
                    "status",
                    // {
                    //   name: "map",
                    //   icon: '<span style="width:23px;height:23px;display: inline-block;border:1px solid #E8E8E8;"><svg style="top: 2px;position: relative;" t="1636128261742" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="25559" width="16" height="16"><path d="M524.665263 1001.633684l-285.642105-285.642105c-75.452632-75.452632-118.568421-177.852632-118.568421-285.642105s43.115789-210.189474 118.568421-285.642106c75.452632-75.452632 177.852632-118.568421 285.642105-118.568421s210.189474 43.115789 285.642105 118.568421c156.294737 156.294737 156.294737 414.989474 0 571.284211l-285.642105 285.642105z m0-921.6c-94.315789 0-180.547368 37.726316-247.915789 102.4s-102.4 153.6-102.4 247.91579c0 94.315789 37.726316 180.547368 102.4 247.915789l247.915789 247.91579 247.91579-247.91579c137.431579-137.431579 137.431579-358.4 0-495.831579-67.368421-64.673684-153.6-102.4-247.91579-102.4z" p-id="25560"></path><path d="M524.665263 592.033684c-88.926316 0-161.684211-72.757895-161.68421-161.68421s72.757895-161.684211 161.68421-161.684211 161.684211 72.757895 161.684211 161.684211-72.757895 161.684211-161.684211 161.68421z m0-269.473684c-59.284211 0-107.789474 48.505263-107.789474 107.789474s48.505263 107.789474 107.789474 107.789473 107.789474-48.505263 107.789474-107.789473-48.505263-107.789474-107.789474-107.789474z" p-id="25561"></path></svg><span>',
                    //   search: "地图,map",
                    //   title: "地图",
                    //   autoExecute: false,
                    //   onClick: () => {
                    //     this.mapModalVisible = true;
                    //   },
                    // },
                    // {
                    //   name: "audio-uploader",
                    //   icon: '<span style="width:23px;height:23px;display: inline-block;border:1px solid #E8E8E8;"><svg style="top: 2px;position: relative;" t="1636128560405" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28042" width="16" height="16"><path d="M877.854 269.225l-56.805-56.806-121.726-123.079c-24.345-21.64-41.928-27.050-68.978-27.050h-451.737c-31.108 0-55.453 24.345-55.453 55.453v789.865c0 29.755 24.345 54.1 55.453 54.1h666.787c31.108 0 55.453-24.345 55.453-54.1v-584.284c0-24.345-8.115-35.165-22.993-54.1v0zM830.516 289.513h-156.891v-156.891l156.891 156.891zM856.213 907.609c0 5.409-4.057 10.821-10.821 10.821h-666.787c-6.762 0-12.172-5.409-12.172-10.821v-789.865c0-6.762 5.409-12.172 12.172-12.172 0 0 451.737 0 451.737 0v205.582c0 12.173 9.468 21.64 21.64 21.64h204.229v574.816zM723.668 413.943c-117.668-1.353-246.157 22.993-363.825 59.511-9.468 4.058-10.821 5.409-10.821 14.877v210.991c-12.172-5.409-27.050-6.762-41.927-5.409-45.985 1.353-82.503 29.755-82.503 60.862 0 31.108 36.517 55.453 82.503 52.748 45.985-2.706 82.503-29.755 82.503-60.863v-193.409c109.553-25.698 209.638-43.28 312.429-51.395v150.128c-12.173-5.409-25.698-6.762-40.576-6.762-45.985 2.706-82.503 29.755-82.503 62.215 0 31.108 36.517 55.453 82.503 52.748 44.632-2.706 82.503-29.755 82.503-60.863v-267.797c0-13.525-6.762-16.23-20.287-17.583z" p-id="28043"></path></svg><span>',
                    //   title: "音频",
                    //   search: "音频,audio",
                    // },
                  ],
                },
              ],
            },
          ],
          ["undo", "redo", "paintformat", "removeformat"],
          ["heading", "fontfamily", "fontsize"],
          ["bold", "italic", "strikethrough", "underline", "moremark"],
          ["fontcolor", "backcolor"],
          ["alignment"],
          ["unorderedlist", "orderedlist", "tasklist", "indent", "line-height"],
          ["link", "quote", "hr"],
        ];

    // 容器加载后实例化编辑器引擎
    const container = this.$refs.container;
    if (container) {
      //实例化引擎
      const engine = new Engine(container, {
        // 启用的插件
        plugins,
        // 启用的卡片
        cards,
        // 所有的卡片配置
        config: pluginConfig,
      });
      // engine.ot = new OT(new OtEngine(container));
      // 设置显示成功消息UI，默认使用 console.log
      engine.messageSuccess = (type, msg, ...args) => {
        console.error(type, msg, ...args);
        message.success(msg);
      };
      // 设置显示错误消息UI，默认使用 console.error
      engine.messageError = (type, error, ...args) => {
        console.error(type, error, ...args);
        message.error(error);
      };
      // 设置显示确认消息UI，默认无
      engine.messageConfirm = (type, msg, ...args) => {
        console.error(type, msg, ...args);
        return new Promise<boolean>((resolve, reject) => {
          Modal.confirm({
            content: msg,
            onOk: () => resolve(true),
            onCancel: () => reject(),
          });
        });
      };
      //卡片最大化时设置编辑页面样式
      engine.on("card:maximize", () => {
        $(".editor-toolbar").css("z-index", "9999").css("top", "55px");
      });
      engine.on("card:minimize", () => {
        $(".editor-toolbar").css("z-index", "").css("top", "");
      });
      // 默认编辑器值，为了演示，这里初始化值写死，正式环境可以请求api加载
      // const value =
      //   '<strong>Hello</strong>,<span style="color:red">am-editor</span>';
      // console.log('this.mdStr', typeof this.mdStr, this.mdStr)
      const value = this.mdStr;
      // 非协同编辑，设置编辑器值，异步渲染后回调
      
      // const md = new MarkdownIt()
      // const html = md.render(value);
      // console.log('html----------', html)
      engine.setValue(value, () => {
        this.loading = false;
        if (this.isReadonly) {
          engine.readonly = true
        }
      });
      // 监听编辑器值改变事件
      engine.on("change", () => {
        this.judgeContent()
        const value = engine.getValue();
        this.value = value;
        this.$nextTick(() => {
          if(this.firstLoadData){
          // this.$emit('contentChange', value)
          // 延迟触发更新文本，500ms内多次输入只触发一次
          clearTimeout(this.changeTimeout)
          this.changeTimeout = setTimeout(() => {
            this.$emit('contentChange', value)
            // this.$nextTick(() => {
            //  })
          }, 500);
        }
        })
      
        // console.log("value", value);
        // console.log("html:", engine.getHtml());
      });
      engine.on("mention:default", () => {
        this.userList = this.$store.state.user.workcodeList.map((item, index) => {
          return {
            ...item,
            key: String(index + 1),
          }
        })
        return this.userList
      });
      engine.on('mention:render-item', (data, root) => {
        const item = $(`<div class="data-mention-item" data-key="${data.key}" data-name="${data.name}">
          <span class="data-mention-item-text">${data.name}${data.workcode?'-'+data.workcode:''}</span>
        </div>`)
        return item
      });
      engine.on('mention:search', (keyword) => {
        return new Promise((resolve) => {
          if (keyword) {
            resolve(this.userList.filter((item) => {
              return item.name.indexOf(keyword) > -1
            }))
          } else {
            resolve(this.userList)
          }
        });
      });
      engine.on('mention:select', (data) => {
        // data['checked'] = true
        // data['notice'] = JSON.parse(JSON.stringify(this.isNotice))
        this.checkedData = data
        return data
      })
      
      this.engine = engine
      this.initOT()
    }
    // const viewContainer = this.$refs.view;
    // if (viewContainer) {
    //   const view = new View(viewContainer, {
    //     // 启用的插件
    //     plugins,
    //     // 启用的卡片
    //     cards,
    //     // 所有的卡片配置
    //     config: pluginConfig,
    //   });
    //   this.view = view;
    // }
    const _this = this

  }
  @Watch("value", { immediate: true, deep: true })
  handleData(value,oldVal) {
    this.firstLoadData = !!oldVal
    // if (this.view) this.view.render(value);

    // this.isNotice = false
    // const dom = document.createElement('div')
    // dom.innerHTML = `<div class="data-mention-check-dingding">
    //   <input type="checkbox" id="myCheckbox">
    //   <label for="myCheckbox">同时发送通知</label>
    // </div>`
    // const htmls = document.querySelectorAll('.data-mention-component-list:not(.data-mention-component-empty)')
    // if (htmls[0]) {
    //   htmls[0].appendChild(dom)
    //   const _this = this
    //   document.getElementById('myCheckbox').addEventListener('change', function() {
    //     _this.isNotice = !_this.isNotice
    //   })
    // }
    // console.log(1111111111, this.userList);
  }
  initOT(){
    const localMember =
		typeof localStorage === 'undefined' ? null : localStorage.getItem('member');

		const getMember = () => {
			return !!localMember ? JSON.parse(localMember) : null;
		};
    const member = getMember();
    const IS_DEV = true
		const wsUrl = process.env.VUE_APP_OT_WS

		//实例化协作编辑客户端
		const ot = new OTClient(this.engine);
    const url =  `${wsUrl}${member?.id ? '?uid=' + member.id : ''}`
    // 连接协同服务端，如果服务端没有对应docId的文档，将使用 defaultValue 初始化
    ot.connect(url, this.$route.params.pathMatch, this.engine.getValue());
    ot.on('ready', (member) => {
    });
    ot.on('error', ({ code, message }) => {
      
    });
    this.otClient.current = ot;
    if(this.otClient.current.members) {
      this.members = this.otClient.current.members
    }
    // 用户加入或退出改变
		this.otClient.current.on('membersChange', this.membersChange);
    // 状态改变
    // const statusChange = (from,to) => {
      
		// };
    // this.otClient.current.on('statusChange', statusChange);
		// 错误监听
		const error = (error) => {
			if (onError) onError(error);
		};
		this.otClient.current.on('error', error);
		// 消息监听
		const message = (message) => {
			// if (onMessage) onMessage(message);
			// 更新评论列表
			if (
				message.type === 'updateCommentList' &&
				this.$refs.comment?.reload
			) {
				this.$refs.comment.reload();
			}
      else if(message.type === 'updateCurrentEditor' && this.$refs.amot){
        this.$refs.amot.updateCurrentUser(message.body)
      }
		};
		this.otClient.current.on('message', message);
    window.addEventListener('beforeunload', () => {
      this.otClient.current.removeMember(this.otClient.current.current)
      this.membersChange(this.otClient.current.members)
    });
		window.addEventListener('pagehide', () => {
      this.otClient.current.removeMember(this.otClient.current.current)
      this.membersChange(this.otClient.current.members)
    });
    this.selection = new OTSelection(this.engine)
  }
  judgeContent(){
    // 获取当前光标后的元素，如果是@符号生成的mention card
    // const nextNode = this.engine.change.range.get().getEndOffsetNode()
    const nextNode = this.engine.change.range.get().getPrevNode()?.get()
    const parentNode = this.engine.change.range.get().getRootBlock().get()
    console.log('---node node-------', nextNode, parentNode);
    // 判断nextNode是不是dom节点，且包含data-card-key="mention"属性
    if(nextNode?.nodeType === 1){
      const cardKey = nextNode.getAttribute('data-card-key')
      const cardVal = nextNode.getAttribute('data-card-value')
      /* 
        判断是不是@生成的mention card
        如果是，获取当前@前的第一个标点符号后的元素文内容，如果前面没有标点符号，则获取前面所有非card元素的文本内容
      */
      if(cardKey === 'mention'){
        // parentNode里面 nextNode 前面的所有元素 preall
        const preall = parentNode.childNodes
        // 判断当前元素是第几个
        const index = Array.prototype.indexOf.call(preall, nextNode)
        // 获取前面元素的所有元素文本内容
        let preText = ''
        for(let i = 0; i < index; i++){
            preText += preall[i].textContent
        }
        // 通过标点符号分隔，找出当前@前的第一个标点符号后的元素内容
        const textArr = preText.split(/[\s\,\.\;\:\?\!\。\；]/)
        let lastText = textArr[textArr.length - 1]
        // 如果当前节点的祖先节点table，则取上一个单元格里面的文本内容table-main-content
        const parentNodesParent = parentNode.parentNode
        if(!lastText && parentNodesParent.classList.contains('table-main-content')){
          if(parentNodesParent.parentNode.previousSibling){
            lastText = parentNodesParent.parentNode.previousSibling.querySelector('.table-main-content').childNodes[0].textContent
          }else{
            lastText = ''
          }
        }
        console.log('--------------parentNode-----------',parentNode.parentNode.parentNode.previousSibling);
        console.log('lastText-------------------', lastText, this.isNotice);
        
        if (this.isNotice && !this.sendUser.length) {
          this.sendUser.push(this.checkedData)
          this.setDingNotify(this.checkedData, lastText)
          setTimeout(() => {
            this.sendUser = []
          }, 800);
        }
      }
    }
  }
  broadcast() {
    this.otClient.current.broadcast('updateCommentList');
  }
  membersChange(val){
    this.otClient.current.broadcast('updateCurrentEditor', val)
  }
  changeTop(top){
    this.top = top
  }
  addComment(event){
    // console.log(event,'eeee')
    event.preventDefault()
    event.stopPropagation()
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);
    
    let highlight = document.createElement('span');
    highlight.style.backgroundColor = 'yellow';
    
    range.surroundContents(highlight);
    
    // 重新设置选中状态
    selection.removeAllRanges();
    selection.addRange(range);
    this.$refs.comment.clickIcon(event)
  }

  addComment(event){
    // console.log(event,'eeee')
    event.preventDefault()
    event.stopPropagation()
    // let selection = window.getSelection();
    // let range = selection.getRangeAt(0);
    
    // let highlight = document.createElement('span');
    // highlight.style.backgroundColor = 'yellow';
    
    // range.surroundContents(highlight);
    
    // // 重新设置选中状态
    // selection.removeAllRanges();
    // selection.addRange(range);
    this.$refs.comment.clickIcon(event)
  }
  setDingNotify(data, text) {
    if (data.workcode === this.$store.state.user.workcode) {
      return
    }
    const params = {
      user: data.workcode,
      title: this.title,
      content: text,
      url: window.location.href
    }
    sendDDingMsg(params).then(res => {
      if (res.code === '000000') {
        this.$message.success('发送成功')
      } else {
        this.$message.error(res.message)
      }
    })
  }
  updateNotice(data) {
    this.isNotice = data.target.checked
  }

  onUnmounted() {
    if (this.engine) this.engine.destroy();
  }
}
</script>
<style scoped lang="less">
#app {
  padding: 0;
}
#nav {
  position: relative;
}
.am-engine ::selection {
    background: rgba(180, 213, 254, 0.5) !important;
    color: inherit!important;
}
.editor-toolbar {
  // position: fixed;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.02);
  z-index: 1000;
}
.editor-wrapper {
  position: relative;
  width: 100%;
  min-width: 1600px;
}

.editor-wrapper.editor-mobile {
  min-width: auto;
  padding: 0 12px;
}

.editor-container {
  background: #fafafa;
  background-color: #fafafa;
  // padding: 62px 0 64px;
  padding-bottom: 60px;
  height: calc(100vh - 100px);
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  position: relative;
}

.editor-mobile .editor-container {
  padding: 0;
  height: auto;
  overflow: hidden;
}

.editor-content {
  position: relative;
  // width: 812px;
  width: 1000px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #f0f0f0;
  min-height: 800px;
}

.editor-mobile .editor-content {
  width: auto;
  min-height: calc(100vh - 68px);
  border: 0 none;
}

.editor-content .am-engine {
  padding: 40px 16px 60px;
}

.editor-mobile .editor-content .am-engine {
  padding: 18px 0 0 0;
}


.data-comment-button-container {
	display: flex;
	position: absolute;
	right: -24px;
	border: 1px solid rgba(226, 226, 226, 0.84);
	border-radius: 2px;
	background: #fff;
	width: 20px;
	text-align: center;
	height: 20px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #888888;
}

.data-comment-button-container:hover {
	color: #347eff;
	border-color: #347eff;
}

.data-comment-button-container .data-icon {
	font-size: 12px;
}
</style>
<style lang="less">
.data-mention-check-dingding {
  padding: 8px 16px;
  height: 45px;
  box-sizing: border-box;
  border-top: 1px solid #dee0e3;
  display: flex;
  position: relative;
  line-height: 28px;
  font-size: 14px;
  input {
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: -8px;
    white-space: nowrap;
    display: flex;
    margin-right: 10px;
  }
}
</style>
