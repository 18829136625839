var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"elementRef",class:[
    'toolbar-dropdown-list',
    `toolbar-dropdown-${_vm.direction || 'vertical'}`,
    { [`toolbar-dropdown-placement-${_vm.placement}`]: !!_vm.placement },
    { 'toolbar-dropdown-dot': _vm.hasDot !== false },
    _vm.className,
  ]},_vm._l((_vm.items),function({
      key,
      placement,
      title,
      content,
      className,
      icon,
      hotkey,
      disabled,
    }){return _c('a-tooltip',{key:key,attrs:{"placement":placement || 'right'},scopedSlots:_vm._u([((!!title || hotkey !== false) && !_vm.isMobile)?{key:"title",fn:function(){return [(!!title)?_c('div',{staticClass:"toolbar-tooltip-title"},[_vm._v(_vm._s(title))]):_vm._e(),(!!_vm.hotkeys[key])?_c('div',{staticClass:"toolbar-tooltip-hotkey",domProps:{"innerHTML":_vm._s(_vm.hotkeys[key])}}):_vm._e()]},proxy:true}:null],null,true)},[_c('a',{class:[
        'toolbar-dropdown-list-item',
        className,
        { 'toolbar-dropdown-list-item-disabled': disabled },
      ],on:{"click":function($event){return _vm.triggerSelect($event, key)}}},[(
          ((typeof _vm.values === 'string' && _vm.values === key) ||
            (Array.isArray(_vm.values) && _vm.values.indexOf(key) > -1)) &&
          _vm.direction !== 'horizontal' &&
          _vm.hasDot !== false
        )?_c('span',{staticClass:"data-icon data-icon-dot"}):_vm._e(),_vm._t("icon",function(){return [(icon)?_c('span',{class:['data-icon', `data-icon-${icon}`]}):_vm._e()]}),_c('div',{domProps:{"innerHTML":_vm._s(typeof content === 'function' ? content() : content)}})],2)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }