





























import { Component, Prop, Vue } from "vue-property-decorator";
import { EngineInterface } from "@aomao/engine";
import AmColorPickerItem from './item.vue'
import AmColorPickerGroup from './group.vue'
import Palette from './palette'

@Component({
    components: {
        AmColorPickerItem,
        AmColorPickerGroup
    },
})
export default class ColorPicker extends Vue {
    @Prop({ type: Object, required: true}) engine!: EngineInterface
    @Prop(Array) colors?: string[][]
    @Prop({ type: String, required: true}) defaultColor!: string
    @Prop({ type: String, required: true}) defaultActiveColor!: string
    @Prop({ type: [Boolean, Object], default: undefined}) setStroke?: boolean
    @Prop(Function) onSelect?: (color: string, event: MouseEvent) => void
    

    activeColors(values:Array<Array<string | {value:string,active:boolean}>>,activeValue:string){
        return values.map(group => group.map(color => {
            const value = typeof color === "string" ? color : color.value
            return { value, active:activeValue === value }
        }))
    }

    colorValues: {
        value: string;
        active: boolean;
    }[][] = []

    text = ''

    mounted(){
        this.colorValues = this.activeColors(this.colors || Palette.getColors(), this.defaultActiveColor)
        this.text = this.engine.language.get(
                    'toolbar',
                    'colorPicker',
                    this.defaultColor === 'transparent'
                        ? 'nonFillText'
                        : 'defaultText',
                )
    }

    triggerSelect(color: string, event: MouseEvent){
        this.colorValues = this.activeColors(this.colorValues, color)
        if (this.onSelect) this.onSelect(color, event);
    }

    triggerMouseDown(event:MouseEvent){
        if ('INPUT' !== (event.target as Element).tagName) {
            event.preventDefault();
        }
    }
}
