<template>
	<div class="doc-comment-layer" ref="containerRef">
		<div class="doc-comment-title">
			{{ lang === 'zh-CN' ? '评论' : 'Comments' }}
		</div>
		<!-- {loading && <Loading />} -->
		<template v-for="(item, index) in list">
			<div :ref="item.id" :key="item.id" class="doc-comment-item"
				:class="{ 'doc-comment-item-active': item.type !== 'view' }" @click.stop.prevent="itemMouseDown(item.id)">
				<div class="doc-comment-item-header">
					<div class="doc-comment-item-title">{{ item.title }}</div>
				</div>
				<div class="doc-comment-item-body">
					<div class="items" v-for="(s, s1) in item.children" :key="s1">
						<div :key="s1" class="doc-comment-item-row" direction="vertical">
							<div class="doc-comment-item-author" size="small">
								{{ s.username }}
								<span class="doc-comment-item-time">
									<!-- {{ moment()
										.startOf('seconds')
										.from(new Date(s.createdAt)) }} -->
									{{ s.createdAt }}
								</span>
								<template v-if='username === s.workcode'>
									<a @click.stop.prevent="onEditfunc(item, s.id)">
										{{ lang === 'zh-CN' ? '编辑' : 'Edit' }}
									</a>
									<a @click.stop.prevent="onRemovefunc(item.id, s.id)">
										{{ lang === 'zh-CN' ? '删除' : 'Remove' }}
									</a>
								</template>
							</div>
							<div class="doc-comment-item-content">
								<div class="item-edit" v-if="item.id === editItem?.id && s.id === editItem.editId">
									<div className="doc-comment-edit-wrapper">
										<div className="doc-comment-edit-input">
											<!-- <el-input size="mini" v-model="editValue" @change="onChangefunc($event)" /> -->
											<commentBox :oldCommentHtml="editValue" :isEdit="true" :item="item" ref="commentBox" @addNewComment="addNewComment" />
										</div>
										<div class="doc-comment-edit-button">
											<el-button size="small" @click.stop.prevent="onCancelfunc($event)">
												{{ lang === 'zh-CN' ? '取消' : 'Cancel' }}
											</el-button>
											<el-button size="small" type="primary" @click.stop.prevent="onSubmitfunc($event)">
												{{ lang === 'zh-CN' ? '确定' : 'Ok' }}
											</el-button>
										</div>
									</div>
								</div>
								<div v-else v-html="s.contentHtml?s.contentHtml:s.content"></div>
							</div>
						</div>
					</div>
					<div class="items" v-if="item.id === editItem?.id && !editItem.editId">
						<div className="doc-comment-edit-wrapper">
							<div className="doc-comment-edit-input">
								<!-- <el-input size="mini" v-model="editValue" @change="onChangefunc($event)" /> -->
								<commentBox :oldCommentHtml="editValue" :isEdit="true" :item="item" ref="commentBox" @addNewComment="addNewComment" />
							</div>
							<div class="doc-comment-edit-button">
								<el-button size="small" @click.stop.prevent="onCancelfunc($event)">
									{{ lang === 'zh-CN' ? '取消' : 'Cancel' }}
								</el-button>
								<el-button size="small" type="primary" @click.stop.prevent="onSubmitfunc($event)">
									{{ lang === 'zh-CN' ? '确定' : 'Ok' }}
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

	</div>
</template>

<script>
import {
	EditorInterface,
	isEngine,
	RangeInterface,
	random,
	NodeInterface,
	isNode,
	Range
} from '@aomao/engine';
import { addComment, getCommentList, updateComment, delComment, delMultComment,updateContentAfterComment } from '@/api/commentUnderline'
import commentBox from '@/components/comment/components/commentBox.vue'
import { sendDDingMsg } from '@/api/space'
export default {
	components: {
		commentBox
	},
	props: {
		editor: {
			default: () => { },
			type: Object
		},
		chapterId: {
			default: null,
			type: Number
		},
		title: {
			default: '未命名文档',
			type: String
		},
		isNotice: {
			default: false,
			type: Boolean
		},
		chapterObj: {
			default: () => {},
			type: Object
		}
	},
	data() {
		return {
			username: sessionStorage.getItem('urlInfo') ? JSON.parse(sessionStorage.getItem('urlInfo')).username : '',
			myrange: {},
			lang: 'zh-CN',
			list: [],
			itemNodes: [],
			editItem: { editId: null },
			editValue: '',
			editing: false,
		}
	},
	mounted() {
		this.getLists()

		//标记数据更新后触发
		const markChange = (
			addIds,
			removeIds,
		) => {
			const commentAddIds = addIds['comment'] || [];
			const commentRemoveIds = removeIds['comment'] || [];

			//更新状态
			// this.updateStatus(commentAddIds, true);
			// this.updateStatus(commentRemoveIds, false);
			this.updateHeight()
			this.rangeTop()
		};
		this.editor.on('mark-range:change', markChange)

		//光标改变时触发
		const markSelect = (
			range,
			selectInfo,
		) => {
			const { key, id } = selectInfo || {}
			// this.$emit('updateNotice', 0)
			const htmls = document.querySelectorAll('.data-mention-component-list:not(.data-mention-component-empty)')
			if (htmls[0] && document.querySelectorAll('.data-mention-check-dingding').length === 0) {
				const dom = document.createElement('div')
				dom.innerHTML = `<div class="data-mention-check-dingding">
					<input type="checkbox" id="myCheckbox">
					<label for="myCheckbox">同时发送通知</label>
				</div>`
				htmls[0].appendChild(dom)
				const _this = this
				document.getElementById('myCheckbox').addEventListener('change', function(e) {
					_this.$emit('updateNotice', e)
				})
			}
			const { root } = this.editor;
			this.editItem =  { editId: null }
			if (isEngine(this.editor))
				this.editor.command.executeMethod(
					'mark-range',
					'action',
					'comment',
					'revoke',
				);
			const rangeRect = range.getClientRect();
			const rootRect = root.get().getBoundingClientRect();
			const top = rangeRect.y - rootRect.y;
			const btnRef = document.querySelector('.data-comment-button-container')
			btnRef.style.top = `${top}px`
			btnRef.style.right = `-24px`
			btnRef.style.display = 'flex'
			this.select(key === 'comment' ? id : undefined);
			if (key === 'comment' && id) {
				this.editor.command.executeMethod(
					'mark-range',
					'action',
					key,
					'preview',
					id,
				)
				this.rangeTop()
			}
		};
		this.editor.on('mark-range:select', markSelect)
	},
	methods: {
		delMoreRenderId() {
			let delIds = []
			const value = this.editor.getValue()
			this.list.forEach(item => {
				if (!value.includes(item.id)) {
					delIds.push(item.id)
				}
			})
			if(delIds.length){
				delMultComment({ renderId: delIds })
			}
		},
		rangeTop() {
			this.renderItemTop()
			this.$nextTick(() => {
				this.list.forEach(ele => {
					this.itemNodes.push(this.$refs[ele.id][0])
				});
				this.normalize()
			})
		},
		getLists() {
			getCommentList({ encryptionStr: this.$route.params.pathMatch }).then(res => {
				if (res.code === '000000') {
					this.list = this.formatData(JSON.parse(JSON.stringify(res.data)))
					this.rangeTop()
				}
			}).catch(err1 => {
				this.list = []
			})
		},
		renderItemTop() {
			const tempList = [];
			this.list.forEach((item) => {
				//获取评论编号对应在编辑器中的所有节点
				const elements =
					this.editor.command.executeMethod(
						'mark-range',
						'action', //插件名称
						'comment', //标记类型
						'find', //调用的方法
						item.id,
					)
				if (elements.length === 0) return;
				//获取目标评论在编辑器中的 top
				const top = this.getRectTop(elements[0]);
				if (top < 0) return;
				tempList.push({
					...item,
					top,
					type: this.list.find((c) => c.id === item.id)?.type || 'view',
				});
			});
			this.delMoreRenderId()
			//根据top大小排序，越小排在越前面
			const sortArr = tempList.sort((a, b) => (a.top < b.top ? -1 : 1))
			// 按照id顺序再重新排序
			// const commentlist = [].slice.apply(document.querySelectorAll("span[data-comment-id]")).map(i=>{
			// 	const _id = i.dataset.commentId.includes(',')?i.dataset.commentId.split(',')[i.dataset.commentId.split(',').length-1]:i.dataset.commentId
			// 	return _id
			// })
			// const lastArr = []
			// commentlist.forEach(i=>{
			// 	sortArr.forEach(seI=>{
			// 		if(i === seI.id){
			// 			lastArr.push(seI)
			// 		}
			// 	})
			// })
			// this.updateList(lastArr);
			this.updateList(sortArr);
		},
		normalize() {
			let list = this.list
			let itemNodes = this.itemNodes
			if (list.length === 0) return;
			let activeIndex = list.findIndex((item) => item.type !== 'view');
			if (activeIndex < 0) activeIndex = 0;
			const activeItem = list[activeIndex];
			const element = itemNodes[activeIndex];
			if(!element) return
			element.style.top = `${activeItem.top}px`;
			element.style.display = activeItem.status ? 'block' : 'none';
			let nextTop = activeItem.top;
			for (let i = activeIndex - 1; i >= 0; i--) {
				//获取当前评论循环项的dom节点
				const curElement = itemNodes[i];
				//获取位置
				const curRect = curElement.getBoundingClientRect();
				//如果其下方的评论项的 top 超过了 当前项在编辑器中评论区域的top，就用其下方的评论项 top - 当前高度 - 预留 16px 空隙
				const nextSourceTop =
					list[i + 1].top === nextTop ? list[i + 1].top : nextTop;
				const top =
					nextSourceTop - 16 < list[i].top + curRect.height
						? nextTop - curRect.height - 16
						: list[i].top;
				nextTop = top;
				curElement.style.top = `${top}px`;
				curElement.style.display = list[i].status ? 'block' : 'none';
			}
			let prevTop = activeItem.top;
			for (let i = activeIndex + 1; i < list.length; i++) {
				//获取当前评论循环项的dom节点
				const curElement = itemNodes[i];
				//获取位置
				//const curRect = curElement.getBoundingClientRect()
				//如果其上方的评论项的 top + 其上方评论项的高度 大于 当前项在编辑器中评论区域的top，就用其上方的评论项 + 其上方评论项的高度 + 16 预留 16px 空隙
				const prevElement = itemNodes[i - 1];
				const prevRect = prevElement.getBoundingClientRect();

				const prevSourceTop =
					list[i - 1].top === prevTop ? list[i - 1].top : prevTop;

				const top =
					prevSourceTop + prevRect.height + 16 > list[i].top
						? prevTop + prevRect.height + 16
						: list[i].top;
				prevTop = top;
				curElement.style.top = `${top}px`;
				curElement.style.display = list[i].status ? 'block' : 'none';
			}
		},
		updateHeight() {
			if (this.$refs.containerRef)
				this.$refs.containerRef.style.minHeight = `${this.editor.root.height()}px`;
		},
		updateStatus(ids, status) {
			this.updateHeight();
			if (ids.length === 0) return;
			dispatch({
				type: 'comment/updateStatus',
				payload: {
					ids: ids.join(','),
					status,
				},
			})?.then(() => {
				const data = [...this.list];
				data.forEach((item) => {
					if (ids.indexOf(item.id) > -1) {
						item.status = status;
						if (item.id === this.editItem?.id && status === false) {
							this.updateEditItem(undefined);
						}
					}
				});
				if (status) {
					if (load) load();
				}
				//重新设置列表
				else this.updateList(data);
			});
		},
		// 编辑
		onEditfunc(item, id) {
			this.updateEditItem(item, id)
			this.rangeTop()
		},
		async onRemovefunc(render_id, id) {
			let res = await delComment({ id })
			if (res.code === '000000') {
				const data = [...this.list];
				const itemIndex = data.findIndex((i) => i.id === render_id)
				if (itemIndex > -1) {
					const item = data[itemIndex];
					const childIndex = item.children.findIndex(
						(c) => c.id === id,
					);
					if (childIndex > -1) item.children.splice(childIndex, 1);
					if (item.children.length === 0) {
						data.splice(itemIndex, 1)
						this.editor.command.executeMethod(
							'mark-range',
							'action',
							'comment',
							'remove',
							item.id,
						);
						const value = this.editor.getValue()
						// this.$parent.$parent.$emit('contentChange', value)
						await updateContentAfterComment({contentStr:value,chapterId:this.chapterId})
						await this.delMoreRenderId()
						this.updateEditItem(undefined)
					} else {
						this.updateEditItem(item)
					}
					this.updateList(data)
					this.rangeTop()
					this.$emit('broadcast')
				}
			} else {
				this.$message.error(result.message);
			}
		},
		onChangefunc(val) {
			this.editValue = val
		},
		onSubmitfunc(event) {
			this.editValue = document.getElementById('customInput').innerText
			this.onItemOk(event)
		},
		onCancelfunc(event) {
			this.editValue = ''
			this.editItem =  { editId: null }
			if (isEngine(this.editor))
				this.editor.command.executeMethod(
					'mark-range',
					'action',
					'comment',
					'revoke',
				);
			this.select()
		},
		getRectTop(selectors) {
			//获取选择器节点
			let element = typeof selectors === 'string' ? this.editor.container.get().querySelector(selectors) : isNode(selectors)
				? selectors
				: selectors.get();
			if (!element) return -1;
			//选好计算每个标记评论项距离编辑器根节点的top
			let top = 0;
			while (element && !this.editor.root.equal(element)) {
				const rect = element.getBoundingClientRect();
				const parent = element.parentElement;
				if (!parent) break;
				const parentRect = parent.getBoundingClientRect();
				top += rect.top - parentRect.top;
				element = parent;
			}
			return top;
		},
		updateEditItem(item, id) {
			const element = id && item
				? item.children.find((c) => c.id === id):''
			this.editValue = element?(element.contentHtml?element.contentHtml:element.content): ''
			// this.editValue = id && item
			// 	? item.children.find((c) => c.id === id)?.content || ''
			// 	: ''
			this.editItem = item ? { ...item, editId: id } : undefined
		},
		updateList(data) {
			//清除每个列表项的 ref
			// itemNodes.current = [];
			this.itemNodes = []
			this.list = data
		},
		showEdit(title) {
			const data = [...this.list];
			//增加
			const editItem = {
				id: random(18),
				title,
				top: this.getRectTop(`[data-comment-preview="true"]`),
				type: 'add',
				status: true,
				children: [],
			};
			this.editItem =  { editId: null,...editItem }
			const index = data.findIndex(
				(item, index) =>
					item.top >= editItem.top &&
					(!this.list[index - 1] ||
						this.list[index - 1].top <= editItem.top),
			);
			if (index === -1) data.push(editItem);
			else data.splice(index, 0, editItem);

			this.updateEditItem(editItem);
			this.updateList(data);
			this.$nextTick(() => {
				this.list.forEach(ele => {
					this.itemNodes.push(this.$refs[ele.id][0])
				});
				this.normalize()
			})
		},
		select(id) {
			const data = [...this.list]
			//移除已经在编辑状态的 
			let index = data.findIndex((item) => item.type !== 'view');
			const item = data[index];
			if (!item && !id) return;
			if (item && item.id !== id) {
				//如果增加状态，没有子级，移除整个评论
				if (item.type === 'add') {
					if (item.children.length === 0) data.splice(index, 1);
					else item.type = 'view';
				}
				//编辑状态，设置为浏览状态
				else {
					item.type = 'view';
				}
			}
			//增加目标为编辑状态
			if (id) {
				index = data.findIndex((item) => item.id === id);
				const item = data[index];
				//设置目标为编辑状态
				if (item) {
					item.type = 'add';
					this.updateEditItem(item);
				}
				//如果目标吧id不存在
				else {
					this.updateEditItem(undefined);
				}
			} else {
				this.updateEditItem(undefined);
			}
			if (isEngine(this.editor))
				this.editor.command.executeMethod(
					'mark-range',
					'action',
					'comment',
					'revoke',
				);
			//重新设置列表
			this.updateList(data);
		},
		/**
		 * 渲染编辑区域
		 */
		async onItemOk(event) {
			//提交评论到服务端
			event.preventDefault();
			event.stopPropagation();
			//没有子评论编辑id就认定为增加评论，否则为修改评论内容
			if (!this.editValue || this.editing || !this.editItem) return;
			this.editing = true
			let result
			const domList = document.getElementById('customInput').querySelectorAll('.ot-button')
			const mentionedUser = [...new Set(Array.from(domList).map(ele => ele.attributes.name.value))].join(',')
			const mentionWorkcode = [...new Set(Array.from(domList).map(ele => ele.id))].join(',')
			if (!this.editItem.editId) {
				// result = dispatch({
				// 	type: 'comment/add',
				// 	payload: {
				// 		title: this.editItem.title,
				// 		render_id: this.editItem.id,
				// 		content: this.editValue,
				// 		username: 'test',
				// 	},
				// });
				
				const params = {
					chapterId: this.chapterId,
					renderId: this.editItem.id,
					title: this.editItem.title,
					content: this.editValue,
					mentionedUser: mentionedUser,
					mentionWorkcode: mentionWorkcode
					// contentStr: this.editor.getValue()
				}
				result = await addComment(params)
			} else {
				const params = {
					id: this.editItem.editId,
					content: this.editValue,
					mentionedUser: mentionedUser,
					mentionWorkcode: mentionWorkcode
				}
				result = await updateComment(params)
			}
			if (result.code === '000000') {
				const dataList = [...this.list]
				const index = dataList.findIndex(
					(item) => item.id === this.editItem.id,
				)
				if (index > -1) {
					const item = dataList[index];
					let comment;
					//编辑，就更新评论内容
					if (this.editItem.editId) {
						comment = item.children.find(
							(c) => c.id === this.editItem.editId,
						);
						if (comment) comment.content = this.editValue;
					}
					//增加，就增加到子级评论列表
					else if (!this.editItem.editId) {
						comment = { ...result.data };
						item.children.push(comment);
					}
					//最后在当前评论id内渲染为可继续添加评论
					item.type = 'add';
					this.updateEditItem(item);
					//第一次增加成功，将评论标识应用到编辑器中
					if (
						!this.editItem.editId &&
						item.children.length === 1
					) {
						this.editor.command.executeMethod(
							'mark-range',
							'action',
							'comment',
							'apply',
							item.id,
						);
						this.editor.command.executeMethod(
							'mark-range',
							'action',
							'comment',
							'preview',
							item.id,
						);
					}
					const value = this.editor.getValue()
					updateContentAfterComment({contentStr:value,chapterId:this.chapterId})
					// this.$parent.$parent.$emit('contentChange', value)
					//更新列表，重新获取评论项ref
					this.list = this.formatData(this.list)
					this.updateList(dataList)
					document.getElementById('customInput').innerText = ''
					this.getLists()
				}
				//标志编辑状态为false
				this.editing = false
				// 评论发送通知：选择人员，发送钉钉消息（除了以下情况）作者也要收到通知
				// 自己@自己不发送通知
				// A在B的文章划线评论，给B发通知；
				// A回复B给A的评论，要给B发通知(暂无回复功能)
				const params = {
					user: this.chapterObj && this.chapterObj.workcode !== this.$store.state.user.workcode
						? this.chapterObj.workcode
						: '',
					title: this.title,
					comment: this.editItem.title,
					url: window.location.href
				}
				if (mentionWorkcode) {
					const arr = (params.user ? `${params.user},${mentionWorkcode}` : mentionWorkcode).split(',').filter(ele => ele !== this.$store.state.user.workcode)
					params.user = [...new Set(arr)].join(',')
				}
				if (params.user) {
					sendDDingMsg(params).then(res => {
						if (res.code === '000000') {
							this.$message.success('发送成功')
						} else {
							this.$message.error(res.message)
						}
					})
				}
				this.$emit('broadcast')
			} else {
				this.$message.error(result.message);
				//回调增加失败，将评论预览标识从编辑器中移除
				if (isEngine(this.editor)) {
					this.editor.command.executeMethod(
						'mark-range',
						'action',
						'comment',
						'revoke',
					);
					this.editing = false
				}
			}
		},
		itemMouseDown(id) {
			if (this.editItem?.id === id) {
				return;
			}
			this.select(id)
			this.rangeTop()
		},
		clickIcon(event) {
			if (this.editItem !== undefined && this.editItem.id) return;
			event.preventDefault();
			event.stopPropagation();
			if (isEngine(this.editor)) {
				const text = this.editor.command.executeMethod(
					'mark-range',
					'action',
					'comment',
					'preview',
				)
				if (!!text) {
					this.showEdit(text);
				}
			}
		},
		addNewComment(data, item) {
      // this.newComment = data
      // this.oldComment = data
      // console.log('data', data)
      // this.handleInput(data, item)
    },
		formatData(data) {
			data.forEach(item => {
				if (item.children.length) {
					item.children.forEach(ele => {
						if (ele.mentionedUser) {
							const temp = ele.mentionedUser.split(',')
							const code = ele.mentionWorkcode.split(',')
							ele.contentHtml = JSON.parse(JSON.stringify(ele.content))
							temp.forEach((ele1, index) => {
								ele.contentHtml = ele.contentHtml.replaceAll(`@${ele1}`, `<span id="${code[index]}" name="${ele1}" class="ot-button" contenteditable="false"><span class="${ele.username === ele1?'at-button':'mt-button'}" contenteditable="false">@${ele1}</span></span>`)
							})
						}
					})
				}
			})
			return data
		},
		reload(){
			this.getLists()
		}
	}
}
</script>
<style lang="scss" >
/** 编辑器中标记样式 ---- 开始 **/
[data-comment-preview],
[data-comment-id] {
	padding-bottom: 2px;
	text-indent: 0;
	border-bottom: 2px solid #f8e1a1 !important;
}

[data-comment-preview] {
	background: rgb(250, 241, 209) !important;
}

[data-comment-preview]::selection {
	background: transparent !important;
}

[data-card-key][data-comment-id]::before,
[data-card-key][data-comment-preview]::before {
	bottom: -2px;
}

/** 编辑器中标记样式 ---- 结束 **/

.data-comment-button-container {
	display: none;
	position: absolute;
	border: 1px solid rgba(226, 226, 226, 0.84);
	border-radius: 2px;
	background: #fff;
	width: 20px;
	text-align: center;
	height: 20px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #888888;
}

.data-comment-button-container:hover {
	color: #347eff;
	border-color: #347eff;
}

.data-comment-button-container .data-icon {
	font-size: 12px;
}

.doc-comment-layer {
	position: absolute;
	top: 20px;
	padding-left: 16px;
	min-width: 260px;
	right: 16px;
}

.doc-comment-title {
	position: relative;
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 700;
	padding: 0 2px 8px;
	border-bottom: 1px solid #e8e8e8;
}

.doc-comment-item {
	position: absolute;
	background-color: #fff;
	border: 1px solid #dee0e3;
	border-radius: 4px;
	width: calc(100% - 24px);
	display: none;
}

.doc-comment-item-active::before {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	content: '';
	position: absolute;
	width: calc(100% + 2px);
	height: 6px;
	background: #ffc60a;
	margin-top: -1px;
	margin-left: -1px;
	margin-right: -1px;
}

.doc-comment-item-header {
	padding: 12px;
	margin-top: 2px;
	position: relative;
	line-height: 18px;
}

.doc-comment-item-header::before {
	content: '';
	position: absolute;
	width: 2px;
	height: 18px;
	border-radius: 15px;
	background: #bbbfc4;
}

.doc-comment-item-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-left: 7px;
	font-size: 12px;
	color: #646a73;
}

.doc-comment-item-body {
	padding: 0px 12px 12px;
}

.doc-comment-item-body .ant-space-vertical {
	display: flex;
}

.doc-comment-edit-wrapper {}

.doc-comment-edit-input {}

.doc-comment-edit-button {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 12px;
}

.doc-comment-edit-button .ant-btn {
	margin-left: 12px;
	font-size: 12px;
}

.doc-comment-item-row {
	font-size: 12px;
}

.doc-comment-item-author {
	margin-bottom: 0;
	line-height: 18px;
	color: #646a73;
}

.doc-comment-item-time {}

.doc-comment-item-content {
	margin: 6px 0;
	word-wrap: break-word;
	white-space: pre-wrap;
	hyphens: auto;
	word-break: break-word;
	color: #1f2329;
}
</style>