


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TableSelector extends Vue {
  @Prop({ type: Number, default: 10 }) maxRows!: number;
  @Prop({ type: Number, default: 10 }) maxCols!: number;
  @Prop({ type: Number, default: 4 }) minRows!: number;
  @Prop({ type: Number, default: 4 }) minCols!: number;
  @Prop(Function) onSelect?: (
    event: MouseEvent,
    rows: number,
    cols: number
  ) => void;

  currentRows = 4;
  currentCols = 4;
  selectedRows = 0;
  selectedCols = 0;

  triggerSelect(event: MouseEvent, rows: number, cols: number) {
    event.preventDefault();
    if (this.onSelect) this.onSelect(event, rows + 1, cols + 1);
  }

  triggerHover(rows: number, cols: number) {
    const showRows = Math.max(this.minRows, Math.min(this.maxRows, rows + 2));
    const showCols = Math.max(this.minCols, Math.min(this.maxCols, cols + 2));
    this.currentRows = showRows;
    this.currentCols = showCols;
    this.selectedRows = rows + 1;
    this.selectedCols = cols + 1;
  }
}
