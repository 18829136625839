export default {
	dnd: {
		title: '拖动调整位置',
	},
	copy: {
		title: '复制',
		success: '复制成功',
		error: '复制失败',
	},
	delete: {
		title: '删除',
	},
	copyAnchor: {
		title: '复制锚点链接',
	},
	link: {
		placeholder: '请输入链接或锚点，回车确认',
		save: '保存',
		edit: '编辑',
		delete: '取消链接',
		open: '打开链接',
		text: '链接',
	},
	copyContent: {
		title: '复制内容',
	},
	maximize: {
		title: '最大化',
		back: '返回文档',
	},
	expand: {
		title: '嵌入预览',
	},
	collapse: {
		title: '紧凑展示',
	},
	card: {
		lockAlert: '请等待对方编辑完毕后，再进入编辑',
	},
	preferences: {
		title: '设置',
	},
	download: {
		title: '下载',
	},
	more: {
		title: '更多',
	},
	checkMarkdown: {
		title: '检测到粘贴内容符合 Markdown 语法，是否需要转换？',
	},
	searchEmtpy: {
		title: '无匹配卡片',
	},
};
