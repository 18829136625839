

































import { Component, Vue, Prop } from "vue-property-decorator";
import { EngineInterface, isMobile } from '@aomao/engine'
import { Popover } from 'ant-design-vue'
import AmButton from './button.vue'
import { GroupButtonProps, GroupColorProps, GroupDropdownProps, ToolbarCollapseGroupProps } from "../types";
import { VNode } from "vue";
import AmDropdown from './dropdown.vue'
import AmColor from './color/color.vue'
import AmCollapse from './collapse/collapse.vue'

@Component({
    components:{
        AmButton,
        AmCollapse,
        AmDropdown,
        AmColor,
        'a-popover': Popover
    }
})
export default class ToolbarGroup extends Vue {
    @Prop(Object) engine!: EngineInterface
    @Prop({ type: Array, default: []}) items!: (GroupButtonProps
				| GroupDropdownProps
				| GroupColorProps
				| ToolbarCollapseGroupProps)[]
	@Prop(String) icon?: string
    @Prop([String, Function]) content?: string | (() => string) | VNode
    @Prop(Boolean) popup?: boolean
    isMobile = false

    mounted() {
        this.isMobile = isMobile
    }

    getPopupContainer(){
        return document.querySelector('.data-toolbar-popup-wrapper') || document.querySelector('.editor-toolbar') || document.body
    }
}
