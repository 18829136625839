export default {
	dnd: {
		title: 'Drag to reposition',
	},
	copy: {
		title: 'Copy',
		success: 'Copied successfully',
		error: 'Copy error',
	},
	delete: {
		title: 'Delete',
	},
	copyAnchor: {
		title: 'Copy anchor link',
	},
	link: {
		placeholder: 'Please enter a link or anchor and press Enter to confirm',
		save: 'Apply',
		edit: 'Change',
		delete: 'Remove link',
		open: 'Open link',
		text: 'link',
	},
	copyContent: {
		title: 'copy content',
	},
	maximize: {
		title: 'Maximize',
		back: 'Back to document',
	},
	expand: {
		title: 'Embedded preview',
	},
	collapse: {
		title: 'Compact display',
	},
	card: {
		lockAlert: 'Please wait for the other user to finish editing',
	},
	preferences: {
		title: 'Preferences',
	},
	download: {
		title: 'Download',
	},
	more: {
		title: 'More',
	},
	checkMarkdown: {
		title: 'It is detected that the paste content conforms to the Markdown syntax. Do you need to do style conversion?',
	},
	searchEmtpy: {
		title: 'No matching card',
	},
};
