import { render, staticRenderFns } from "./MentionHover.vue?vue&type=template&id=468cb547&scoped=true&"
import script from "./MentionHover.vue?vue&type=script&lang=js&"
export * from "./MentionHover.vue?vue&type=script&lang=js&"
import style0 from "./MentionHover.vue?vue&type=style&index=0&id=468cb547&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468cb547",
  null
  
)

component.options.__file = "MentionHover.vue"
export default component.exports