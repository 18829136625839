






























import { Component, Prop, Vue } from "vue-property-decorator";
import { VNode } from "vue";
import { EngineInterface, isMobile } from "@aomao/engine";
import { CollapseGroupProps } from '../../types';
import AmButton from '../button.vue';
import AmCollapseGroup from './group.vue';

@Component({
    components: {
        AmButton,
        AmCollapseGroup
    },
})
export default class AmCollapse extends Vue  {
    @Prop(Object) engine?: EngineInterface
    @Prop(String) header?: string
    @Prop({ type: Array, required: true, default: [] }) groups!: Array<CollapseGroupProps>
    @Prop({ type: [Boolean, Object], default: undefined}) disabled?: boolean
    @Prop(String) className?: string
	@Prop({ type: String, default: undefined}) icon?: string
    @Prop({ type: [String, Function], default: undefined}) content?: string | (() => string) | VNode
    @Prop(Function) onSelect?: (event: MouseEvent, name: string) => boolean | void

    isCustomize = false
    visible = false
    isRight = false

    show(){
        this.visible = true
        setTimeout(() => {
            document.addEventListener('click', this.hide);
        }, 10);
    };

    hide(event?: MouseEvent){
        if (event) {
            // let node = event.target;
            // while (node) {
            //     if (node === collapse.value) {
            //         return;
            //     }
            //     node = (node as Element).parentNode;
            // }
        }
        document.removeEventListener('click', this.hide);
        this.visible = false
    };

    mounted(){
        this.isCustomize = !(this.icon || this.content);
        this.visible = this.isCustomize
        if (this.$refs.collapse && isMobile) {
			const rect = (this.$refs.collapse as Element).getBoundingClientRect();
			this.isRight = rect.left > window.visualViewport.width / 2;
		}
    }

    unmounted(){
        if(this.isCustomize) document.removeEventListener('click', this.hide);
    }

    triggerClick(){
        if (this.visible) {
            this.hide();
        } else {
            this.show();
        }
    };

    triggerSelect(event:MouseEvent,name:string){
        this.hide()
        if(this.onSelect) this.onSelect(event,name)
    }
}
