





















import { Component, Prop, Vue } from "vue-property-decorator";
import { LanguageInterface } from "@aomao/engine";
import { Tooltip } from "ant-design-vue";

@Component({
  components: {
    "a-tooltip": Tooltip,
  },
})
export default class LinkPreview extends Vue {
  @Prop({ type: Object, required: true }) language!: LanguageInterface;
  @Prop(String) href?: string;
  @Prop(String) className?: string;
  @Prop(Function) onEdit?: (event: MouseEvent) => void;
  @Prop(Function) onRemove?: (event: MouseEvent) => void;
  @Prop(Function) onLoad?: () => void;
  @Prop(Boolean) readonly?: boolean;

  openTitle = "";
  editTitle = "";
  removeTitle = "";

  mounted() {
    this.openTitle = this.language.get<string>("link", "link_open");
    this.editTitle = this.language.get<string>("link", "link_edit");
    this.removeTitle = this.language.get<string>("link", "link_remove");

    if (this.onLoad) this.onLoad();
  }
}
