var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"buttonRef",class:[
    'toolbar-dropdown',
    'colorpicker-button',
    { 'toolbar-dropdown-right': _vm.isRight },
  ]},[_c('div',{class:[
      'toolbar-dropdown-trigger colorpicker-button-group',
      { 'colorpicker-button-group-active': _vm.visible },
    ]},[_c('am-button',{staticClass:"colorpicker-button-text",attrs:{"name":_vm.name,"title":_vm.buttonTitle,"on-click":_vm.triggerClick,"placement":_vm.placement,"disabled":_vm.disabled}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.buttonContent)}})]),_c('am-button',{ref:"targetRef",staticClass:"colorpicker-button-dropdown toolbar-dropdown-trigger-arrow",attrs:{"name":_vm.name,"title":_vm.dropdownTitle,"on-click":_vm.toggleDropdown,"placement":_vm.placement,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"colorpicker-button-dropdown-empty"})]},proxy:true}])},[_c('span',{staticClass:"data-icon data-icon-arrow"})])],1),(_vm.visible)?_c('div',{ref:"elementRef",class:[
      'toolbar-dropdown-list',
      { [`toolbar-dropdown-placement-${_vm.listPlacement}`]: !!_vm.listPlacement },
    ],attrs:{"data-element":"ui"}},[_c('am-color-picker',{attrs:{"engine":_vm.engine,"colors":_vm.colors,"default-active-color":_vm.currentColor,"default-color":_vm.defaultColor,"on-select":_vm.triggerSelect,"set-stroke":_vm.setStroke}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }