/**
 * 卡片类型
 */
export enum CardType {
	INLINE = 'inline',
	BLOCK = 'block',
}

export enum CardActiveTrigger {
	CARD_CHANGE = 'card_change',
	CLICK = 'click',
	MOUSE_DOWN = 'mouse_down',
}

export enum SelectStyleType {
	NONE = 'none',
	BACKGROUND = 'background',
	BORDER = 'border',
}
