






















import { Component, Prop, Vue } from "vue-property-decorator";
import { Select } from 'ant-design-vue'
const ASelectOption = Select.Option
Vue.use(Select)
Vue.use(ASelectOption)
@Component({
    components: {
        'a-select': Select,
        ASelectOption
    },
})
export default class CodeblockSelect extends Vue {
    @Prop({type: Array, default: []}) modeDatas?: {value: string, syntax: string, name: string,}[]
    @Prop(String) defaultValue?: string
    @Prop(Function) getContainer?: () => HTMLElement
    @Prop(Function) onSelect?: (value: string) => void
 
    filter(input: string, option: any){
        input = input.toLowerCase();
        const key = option.key || '';
        let name = option.name || '';
        name = name.toLowerCase();
        return key.includes(input) || name.includes(input);
    }
}
