import { render, staticRenderFns } from "./button.vue?vue&type=template&id=4d979b58&"
import script from "./button.vue?vue&type=script&lang=ts&"
export * from "./button.vue?vue&type=script&lang=ts&"
import style0 from "./button.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "button.vue"
export default component.exports