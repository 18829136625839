<template>
<div
  class="wrapper" id="usersDialog">
  <div v-if="!mocklist.length" class="empty">无搜索结果</div>
  <div
    v-for="(item,i) in mocklist"
    :key="i"
    class="item"
    :class="{'active': i === index}"
    ref="usersref"
    @click="clickat($event,item)"
    @mouseenter="hoverat(i)"
  >
    <div class="name">{{item.name}}-{{item.workcode}}</div>
  </div>
</div>
</template>

<script>
import { getDDusers } from '@/api/user'
export default {
  name: 'atdialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default: function() {
        return {}
      }
    },
    querystring: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      index: -1,
      mocklist: []
    }
  },
  watch: {
    querystring(val) {
      val ? this.mocklist = this.users.filter(({ name }) => name.startsWith(val)) : this.mocklist = this.users.slice(0)
    },
    '$store.state.user.workcodeList': {
      handler(val){
        this.mocklist = val
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    users: function() {
      return JSON.parse(JSON.stringify(this.$store.state.user.workcodeList))
    },
  },
  mounted() {
    document.addEventListener('keyup', this.keydownhandler)
    this.getUsers()
  },
  destroyed() {
    document.removeEventListener('keyup', this.keydownhandler)
  },
  methods: {
    keydownhandler(e) {
      if (e.code === 'Escape') {
        this.$emit('onhide')
        return
      }
      // 键盘按下 => ↓
      if (e.code === 'ArrowDown') {
        if (this.index >= this.mocklist.length - 1) {
          this.index = 0
        } else {
          this.index = this.index + 1
        }
      }
      // 键盘按下 => ↑
      if (e.code === 'ArrowUp') {
        if (this.index <= 0) {
          this.index = this.mocklist.length - 1
        } else {
          this.index = this.index - 1
        }
      }
      // 键盘按下 => 回车
      if (e.code === 'Enter') {
        if (this.mocklist.length) {
          const user = {
            name: this.mocklist[this.index].name,
            workcode: this.mocklist[this.index].workcode
          }
          this.$emit('onpickuser', user)
          this.index = -1
        }
      }
    },
    clickat(e, item) {
      const user = {
        name: item.name,
        workcode: item.workcode
      }
      this.$emit('onpickuser', user)
      this.index = -1
    },
    hoverat(index) {
      this.index = index
    },
    getUsers() {
      if (this.$store.state.user.workcodeList.length === 0) {
        this.$store.dispatch('GetWorkcode')
      }
      // getDDusers(0).then(res => {
      //   if (res.code === '000000') {
      //     this.users = JSON.parse(JSON.stringify(res.data))
      //     this.mocklist = JSON.parse(JSON.stringify(res.data))
      //   } else {
      //     this.users = []
      //   }
      // }).catch(error => {
      //   console.log(error)
      // })
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    position: absolute;
    z-index: 99;
    width: 238px;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 #000;
    box-sizing: border-box;
    padding: 6px 0;
    max-height: 250px;
    overflow-y: auto;
  }
  .empty{
    font-size: 14px;
    padding: 0 20px;
    color: #999;
  }
  .item {
    font-size: 14px;
    padding: 0 20px;
    line-height: 34px;
    cursor: pointer;
    color: #606266;
    &.active {
      background: #f5f7fa;
      color: #3370ff;
      .id {
        color: #3370ff;
      }
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    .id {
      font-size: 12px;
      color: rgb(83, 81, 81);
    }
  }
</style>
