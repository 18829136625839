import { render, staticRenderFns } from "./commentBox.vue?vue&type=template&id=5faece39&"
import script from "./commentBox.vue?vue&type=script&lang=js&"
export * from "./commentBox.vue?vue&type=script&lang=js&"
import style0 from "./commentBox.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "commentBox.vue"
export default component.exports