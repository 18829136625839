var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"buttonRef",class:[
    'toolbar-dropdown',
    { 'toolbar-dropdown-right': _vm.isRight },
    _vm.className,
  ],on:{"click":_vm.triggerClick,"mousedown":_vm.triggerMouseDown}},[_c('div',{class:[
      'toolbar-dropdown-trigger',
      {
        'toolbar-dropdown-trigger-active': _vm.visible,
        'toolbar-dropdown-trigger-arrow': _vm.hasArrow !== false,
      },
    ]},[_c('am-button',{ref:"targetRef",attrs:{"name":_vm.name,"placement":_vm.placement,"title":_vm.title,"active":_vm.visible,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("default",function(){return [(_vm.buttonContent && _vm.buttonContent.icon)?_c('span',{class:['data-icon', `data-icon-${_vm.buttonContent.icon}`]}):_vm._e(),(
              _vm.buttonContent && !_vm.buttonContent.icon && _vm.buttonContent.content
            )?_c('span',{staticClass:"toolbar-dropdown-button-text",domProps:{"innerHTML":_vm._s(_vm.buttonContent.content)}}):_vm._e()]},{"item":_vm.content}),(_vm.hasArrow !== false)?_c('span',{staticClass:"data-icon data-icon-arrow"}):_vm._e()]},proxy:true}],null,true)})],1),(_vm.visible)?_c('am-dropdown-list',{attrs:{"hasDot":_vm.hasDot,"engine":_vm.engine,"direction":_vm.direction,"name":_vm.name,"items":_vm.items,"values":_vm.valuesVar,"on-select":_vm.triggerSelect}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }