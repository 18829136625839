
//引入插件 begin
import Redo from "@aomao/plugin-redo";
import MarkRange from'./plugin-mark-range';
import Undo from "@aomao/plugin-undo";
import Bold from "@aomao/plugin-bold";
import Code from "@aomao/plugin-code";
import Backcolor from "@aomao/plugin-backcolor";
import Fontcolor from "@aomao/plugin-fontcolor";
import Fontsize from "@aomao/plugin-fontsize";
import Italic from "@aomao/plugin-italic";
import Underline from "@aomao/plugin-underline";
import Hr, { HrComponent } from "@aomao/plugin-hr";
import Tasklist, { CheckboxComponent } from "@aomao/plugin-tasklist";
import Orderedlist from "@aomao/plugin-orderedlist";
import Unorderedlist from "@aomao/plugin-unorderedlist";
import Indent from "@aomao/plugin-indent";
import Heading from "@aomao/plugin-heading";
import Strikethrough from "@aomao/plugin-strikethrough";
import Sub from "@aomao/plugin-sub";
import Sup from "@aomao/plugin-sup";
import Alignment from "@aomao/plugin-alignment";
import Quote from "@aomao/plugin-quote";
import PaintFormat from "@aomao/plugin-paintformat";
import RemoveFormat from "@aomao/plugin-removeformat";
import SelectAll from "@aomao/plugin-selectall";
import Image, { ImageComponent, ImageUploader } from "@aomao/plugin-image";
import Table, { TableComponent } from "@aomao/plugin-table";
import File, { FileComponent, FileUploader } from "@aomao/plugin-file";
import Video, { VideoComponent, VideoUploader } from "@aomao/plugin-video";
import Math, { MathComponent } from "@aomao/plugin-math";
import Fontfamily from "@aomao/plugin-fontfamily";
import Status, { StatusComponent } from "@aomao/plugin-status";
import LineHeight from "@aomao/plugin-line-height";
import Mention, { MentionComponent } from "@aomao/plugin-mention";
import {
  fontFamilyDefaultData,
  ToolbarPlugin,
  ToolbarComponent,
} from "../../../packages/toolbar/src";
import Link from "../../../packages/link/src";
import CodeBlock, { CodeBlockComponent } from "../../../packages/codeblock/src";
import MapPlugin, { MapComponent } from "../../../packages/map/src";
import Audio, { AudioComponent, AudioUploader } from "../../../packages/audio/src";
import MentionHover from "./MentionHover.vue";
import { creatComponent } from "@/utils";
import AmLoading from "./Loading.vue";
const DOMAIN = "http://localhost:7001";

export const plugins = [
  MarkRange,
  Redo,
  Undo,
  Bold,
  Code,
  Backcolor,
  Fontcolor,
  Fontsize,
  Italic,
  Underline,
  Hr,
  Tasklist,
  Orderedlist,
  Unorderedlist,
  Indent,
  Heading,
  Strikethrough,
  Sub,
  Sup,
  Alignment,
  Quote,
  PaintFormat,
  RemoveFormat,
  SelectAll,
  Image,
  ImageUploader,
  Table,
  File,
  FileUploader,
  Video,
  VideoUploader,
  Math,
  Fontfamily,
  Status,
  LineHeight,
  Mention,
  Link,
  CodeBlock,
  ToolbarPlugin,
  MapPlugin,
  Audio,
  AudioUploader,
];

export const cards = [
  HrComponent,
  CheckboxComponent,
  ImageComponent,
  TableComponent,
  FileComponent,
  VideoComponent,
  MathComponent,
  StatusComponent,
  MentionComponent,
  CodeBlockComponent,
  ToolbarComponent,
  MapComponent,
  AudioComponent,
];
const Base64 = require('js-base64').Base64
export const pluginConfig = {
  [Italic.pluginName]: {
    // 默认为 _ 下划线，这里修改为单个 * 号
    markdown: "*",
  },
  [Image.pluginName]: {
    onBeforeRender: (status, url) => {
      // if (url.startsWith("data:image/")) return url;
      return url;
    },
  },
  [ImageUploader.pluginName]: {
    file: {
      headers: { 
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
        systemCode: process.env.VUE_APP_SYSTEM_CODE 
      },
      crossOrigin: true,
      action: `${process.env.VUE_APP_BASE_API}/contentSpace/aomaoUpload?code=${Base64.encode(sessionStorage.getItem('urlInfo'))}`,
      // // action:'https://dropbox.suntekcorps.com/api.php?action=uploadFiles&code=eyJ1c2VySWQiOjY5OTIsInVzZXJuYW1lIjoiU1RHRjAwNTkiLCJzeXN0ZW1Db2RlIjoiNTUiLCJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6VXhNaUo5LmV5SndiM05wZEdsdmJrbGtJam8wTVRnMU9Dd2ljbTlzWlVsa0lqb3pMQ0prWlhCaGNuUnRaVzUwU1dRaU9qTTRPVGsyTENKdVlXMWxJam9pSlVVMkpUbEVKVGhGSlVVNUpUbEVKVGs1SWl3aVpYaHdJam94TmpreE5qY3lPVGt4TENKMWMyVnlTV1FpT2pZNU9USXNJbXAwYVNJNklqZGpaVE0xTjJObFlqQTJOelE1Wm1OaE16RTFZekUxWVRFMVltRmhPRGxrSWl3aWRXTkpaQ0k2TVRBek1Dd2lkWE5sY201aGJXVWlPaUpUVkVkR01EQTFPU0o5LkZNTm9QS3hTXzRGYXFUaVppcWJtRVRoVi1LejI1eGR6d3F2RGpieGM1TG9uQU11WVVtQXZkY205anNDNFJMRG5NQUtRVTVaMDBqT1N1QTNra3FxMnNRIn0%3D&chunks_count=1&chunk=1',
      // action: `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=uploadFiles&code=${Base64.encode(sessionStorage.getItem('urlInfo'))}&chunk=1&chunks_count=1`, //图片上传
      // headers: {
      //   Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
      //   systemCode: process.env.VUE_APP_SYSTEM_CODE 
      // },
      // name: 'files[]',
      // // data: {
      // //   signal: Date.now(),
      // //   name: 'aa.png'
      // // },
      // crossOrigin: true,
      // // parse: (res) => {
      // // if (res.success) return { result: true, data: res.svg };
      // // return { result: false };
      // // },
    },
    remote: {
      headers: { 
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
        systemCode: process.env.VUE_APP_SYSTEM_CODE 
      },
      crossOrigin: true,
       //添加外网图片连接上传,上后端下载图片，并返回一个本地连接,比如图片复制
      action: `${process.env.VUE_APP_BASE_API}/contentSpace/getUrl?code=${Base64.encode(sessionStorage.getItem('urlInfo'))}`,
    },
    isRemote: (src) => src.indexOf(DOMAIN) < 0,
  },
  [FileUploader.pluginName]: {
    // action: `${DOMAIN}/upload/file`,
    limitSize: 1024 * 1024 * 50,
    headers: { 
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
      systemCode: process.env.VUE_APP_SYSTEM_CODE 
    },
    crossOrigin: true,
    action: `${process.env.VUE_APP_BASE_API}/contentSpace/aomaoUpload?code=${Base64.encode(sessionStorage.getItem('urlInfo'))}`,
  },
  [VideoUploader.pluginName]: {
    // action: `${DOMAIN}/upload/video`,
    limitSize: 1024 * 1024 * 50,
    headers: { 
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
      systemCode: process.env.VUE_APP_SYSTEM_CODE 
    },
    crossOrigin: true,
    action: `${process.env.VUE_APP_BASE_API}/contentSpace/aomaoUpload?code=${Base64.encode(sessionStorage.getItem('urlInfo'))}`,
  },
  [Video.pluginName]: {
    onBeforeRender: (status, url) => {
      return url;
    },
  },
  [AudioUploader.pluginName]: {
    // action: `${DOMAIN}/upload/video`,
    limitSize: 1024 * 1024 * 50,
    headers: { 
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('urlInfo')).token}`,
      systemCode: process.env.VUE_APP_SYSTEM_CODE 
    },
    crossOrigin: true,
    action: `${process.env.VUE_APP_BASE_API}/contentSpace/aomaoUpload?code=${Base64.encode(sessionStorage.getItem('urlInfo'))}`,
  },
  [Math.pluginName]: {
    action: `https://g.yanmao.cc/latex`,
    parse: (res) => {
      if (res.success) return { result: true, data: res.svg };
      return { result: false };
    },
  },
  [Mention.pluginName]: {
    // action: `${DOMAIN}/user/search`,
    onLoading: (root) => {
      creatComponent(AmLoading, root.get());
    },
    onEmpty: (root) => {
      // creatComponent(Empty, root.get<HTMLElement>()!);
    },
    onClick: (
      root,
      { key, name }
    ) => {
      console.log("mention click:", key, "-", name);
    },
    onMouseEnter: (
      layout,
      { name }
    ) => {
      creatComponent(MentionHover, layout.get(), { name });
    },
  },
  [Fontsize.pluginName]: {
    //配置粘贴后需要过滤的字体大小
    filter: (fontSize) => {
      return (
        [
          "12px",
          "13px",
          "14px",
          "15px",
          "16px",
          "19px",
          "22px",
          "24px",
          "29px",
          "32px",
          "40px",
          "48px",
        ].indexOf(fontSize) > -1
      );
    },
    defaultSize: "15px",
  },
  [Fontfamily.pluginName]: {
    //配置粘贴后需要过滤的字体
    filter: (fontfamily) => {
      const item = fontFamilyDefaultData.find((item) =>
        fontfamily
          .split(",")
          .some(
            (name) =>
              item.value
                .toLowerCase()
                .indexOf(name.replace(/"/, "").toLowerCase()) > -1
          )
      );
      return item ? item.value : false;
    },
  },
  [LineHeight.pluginName]: {
    //配置粘贴后需要过滤的行高
    filter: (lineHeight) => {
      if (lineHeight === "14px") return "1";
      if (lineHeight === "16px") return "1.15";
      if (lineHeight === "21px") return "1.5";
      if (lineHeight === "28px") return "2";
      if (lineHeight === "35px") return "2.5";
      if (lineHeight === "42px") return "3";
      // 不满足条件就移除掉
      return ["1", "1.15", "1.5", "2", "2.5", "3"].indexOf(lineHeight) > -1;
    },
  },
  'mark-range': {
    keys:["comment"]
  },
  toolbar: {
    popup: {
      items: [
        ["bold", "strikethrough", "fontcolor"],
        {
          icon: "text",
          items: ["italic", "underline", "backcolor", "moremark"],
        },
        [
          {
            type: "button",
            name: "image-uploader",
            icon: "image",
          },
          "link",
          "tasklist",
          "heading",
        ],
        {
          icon: "more",
          items: [
            {
              type: "button",
              name: "video-uploader",
              icon: "video",
            },
            {
              type: "button",
              name: "file-uploader",
              icon: "attachment",
            },
            {
              type: "button",
              name: "math",
              icon: "math",
            },
            {
              type: "button",
              name: "codeblock",
              icon: "codeblock",
            },
            {
              type: "button",
              name: "orderedlist",
              icon: "ordered-list",
            },
            {
              type: "button",
              name: "unorderedlist",
              icon: "unordered-list",
            },
            {
              type: "button",
              name: "hr",
              icon: "hr",
            },
          ],
        },
      ],
    },
  },
};
