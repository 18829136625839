import request from '@/utils/request'

// 添加评论
export function commentAdd(params) {
  return request({
    url: '/commentLine/addComment',
    method: 'post',
    data: params
  })
}

// 编辑评论
export function commentEdit(params) {
  return request({
    url: '/commentLine/editComment',
    method: 'post',
    data: params
  })
}

// 删除评论
export function commentDelete(params) {
  return request({
    url: '/commentLine/delComment',
    method: 'post',
    data: params
  })
}

// 查询评论
export function getComment(params) {
  return request({
    url: '/commentLine/getComment',
    method: 'get',
    params: params
  })
}

// 删除评论
export function commentDeleteBatch(params) {
  return request({
    url: '/commentLine/delCommentBatch',
    method: 'post',
    data: params
  })
}

// 新增评论
export function addComment(params) {
  return request({
    url: '/commentLine/add',
    method: 'post',
    data: params
  })
}
// 获取评论列表
export function getCommentList(params) {
  return request({
    url: '/commentLine/getCommentList',
    method: 'get',
    params: params
  })
}
// 编辑评论
export function updateComment(params) {
  return request({
    url: '/commentLine/update',
    method: 'post',
    data: params
  })
}
// 删除单条评论
export function delComment(params) {
  return request({
    url: '/commentLine/delete',
    method: 'post',
    data: params
  })
}
// 删除多条评论
export function delMultComment(params) {
  return request({
    url: '/commentLine/deleteBatch',
    method: 'post',
    data: params
  })
}
// 评论之后更新内容
export function updateContentAfterComment(params) {
  return request({
    url: '/commentLine/updateContentAfterComment',
    method: 'post',
    data: params
  })
}