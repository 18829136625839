export default {
	link: {
		text: 'Text',
		link: 'Link',
		text_placeholder: 'Description text',
		link_placeholder: 'Link address',
		link_open: 'Open link',
		link_edit: 'Edit link',
		link_remove: 'Remove link',
		ok_button: 'OK',
	},
};
